<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async getStreams (rowsPerPage) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/admin/broadcast/streams?count=' + rowsPerPage
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async createStream (newStream) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/admin/broadcast/streams'
    var streamData = newStream
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: streamData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async stopStream (id) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/admin/broadcast/streams/' + id + '/live'
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async startStream (id) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/admin/broadcast/streams/' + id + '/live'
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async deleteStream (id) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/admin/broadcast/streams/' + id
    const ops = {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
