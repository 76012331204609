<template>
  <v-container id="data-tables" tag="section">
    <base-material-card color="indigo" icon="mdi-book-open-page-variant-outline" inline class="px-5 py-3">
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Broadcast
        </div>
      </template>
      <v-row justify="center" align="center">
        <v-card>
          <v-card-text>
            <v-select :items="pageOptions" v-model="rowsPerPage" append-icon="mdi-chevron-down" class="ml-auto" label="Rows per Page" hide-details @change="initialize"></v-select>
          </v-card-text>
        </v-card>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Active Streams
            </v-card-title>
            <v-data-table :headers="headers" :items="activeStreams" :disable-pagination="true" hide-default-footer item-key="uniqueID" sort-desc :loading="loading">
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon large class="mr-2" @click.stop="stopStream(item)" v-bind="attrs" v-on="on" color="error">
                      mdi-stop-circle
                    </v-icon>
                  </template>
                  <span>Stop Stream</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <h2>No active streams found.</h2>
              </template>
            </v-data-table>
            <!-- fix up pagination later when it's been added server side -->
            <!-- <div class="text-center pt-2 pb-2">
              <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true || items === []" color="blue darken-1" v-on:click="previousPage()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true || items === []" color="blue darken-1" v-on:click="nextPage()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div> -->
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Inactive Streams
            </v-card-title>
            <v-data-table :headers="headers" :items="inactiveStreams" :disable-pagination="true" hide-default-footer item-key="uniqueID" sort-desc :loading="loading">
              <template v-slot:top>
                <v-row>
                  <v-col cols="12" class="text-sm-center">
                    <v-dialog v-model="addStream" :retain-focus="false" max-width="600px" scrollable>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-4 mr-0">
                          Add Stream
                        </v-btn>
                      </template>
                      <v-card :loading="createLoading">
                        <v-card-title>
                          <span class="headline">Add Stream</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field v-model="newStream.title" label="Stream Title*" :rules="titleRules" required></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field v-model="newStream.description" label="Stream Description*" required></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field v-model="newStream.outputLink" label="Stream Link*" required></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" v-on:click.stop="closeAddDialog">Nevermind</v-btn>
                          <v-btn :disabled="!newStream.streamTitle" color="green darken-1" v-on:click.stop="createStream">Create</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon large class="mr-2" @click.stop="startStream(item)" v-bind="attrs" v-on="on" color="success">
                      mdi-play
                    </v-icon>
                  </template>
                  <span>Start Stream</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click.stop="deleteConfirm(item)" color="error" v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <h2>No streams have been found</h2>
              </template>
            </v-data-table>
            <!-- fix up pagination later when it's been added server side -->
            <!-- <div class="text-center pt-2 pb-2">
              <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true || items === []" color="blue darken-1" v-on:click="previousPage()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true || items === []" color="blue darken-1" v-on:click="nextPage()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div> -->
          </v-card>
        </v-col>
      </v-row>
      <v-dialog :retain-focus="false" v-model="deleteConfirmDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text class="text-center">
            This stream will be deleted
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="red darken-1" v-on:click.stop="deleteConfirmDialog = false">
              No
            </v-btn>
            <v-btn color="green darken-1" v-on:click.stop="deleteItem">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>

<script>
import Broadcast from '@/Services/Marketing/Broadcast'
export default {
  name: 'Broadcast',
  data () {
    return {
      loading: true,
      createLoading: false,
      activeStreams: [],
      inactiveStreams: [],
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      nextCursor: null,
      prevCursor: null,
      rowsPerPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Stream Link',
          value: 'outputLink'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      errors: [],
      addStream: false,
      newStream: {
        title: '',
        description: '',
        outputLink: ''
      },
      titleRules: [
        v => !!v || 'Name is required'
      ],
      deleteID: '',
      deleteConfirmDialog: false
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.loading = true
      await Broadcast.getStreams(this.rowsPerPage)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'activeStreams', response.data.activeStreams)
            this.$set(this, 'inactiveStreams', response.data.nonActiveStreams)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.items = []
            this.snack = true
            this.snackColor = 'error'
            this.snackText = response.data.message
            this.snackTimeout = 5000
            this.loading = false
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    async createStream () {
      this.loading = true
      await Broadcast.createStream(this.newStream)
        .then((response) => {
          if (response.status === 200) {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Stream has been created.'
            this.snackTimeout = 5000
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Stream could not be created.'
            this.snackTimeout = 5000
          }
        })
      this.addStream = false
      this.loading = false
      this.initialize()
      this.newStream = {
        title: '',
        description: '',
        outputLink: ''
      }
    },
    async stopStream (item) {
      this.loading = true
      await Broadcast.stopStream(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Stream has been stopped.'
            this.snackTimeout = 5000
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Stream could not be stopped.'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
      this.loading = false
      this.initialize()
    },
    async startStream (item) {
      this.loading = true
      await Broadcast.startStream(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Stream has been started.'
            this.snackTimeout = 5000
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Stream could not be started.'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
      this.loading = false
      this.initialize()
    },
    deleteConfirm (item) {
      this.deleteID = item.id
      this.deleteConfirmDialog = true
    },
    async deleteItem () {
      this.loading = true
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Deleting...'
      this.snackTimeout = -1
      await Broadcast.deleteStream(this.deleteID)
        .then((response) => {
          if (response.status === 200) {
            this.deleteConfirmDialog = false
            this.snackColor = 'success'
            this.snackText = 'Stream was deleted successfully!'
            this.snackTimeout = 3000
          } else {
            this.deleteConfirmDialog = false
            this.snackColor = 'error'
            this.snackText = 'Failed to delete stream.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      this.loading = false
      this.initialize()
    },
    closeAddDialog () {
      this.addStream = false
      this.newStream = {
        title: '',
        description: '',
        outputLink: ''
      }
    }
  }
}
</script>

<style>

</style>
